<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { InputsPasswordProps } from './InputsPassword.props'
import { passwordStrength } from 'check-password-strength'
import { getNode, type FormKitNode } from '@formkit/core'
const { ts } = useI18n()

const props = withDefaults(defineProps<InputsPasswordProps>(), {
  showValidationBar: false,
})

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'remove'): void
  (e: 'click-action-button'): void
}>()

const node = ref<FormKitNode>()

onMounted(() => {
  node.value = getNode(props.id)
})

// Ref to track whether the password is visible or not
const isPasswordVisible = ref<boolean>(false)

// Toggle the visibility of the password
const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value
}

// Computed property to determine the current input type based on password visibility
const currentInputType = computed<'text' | 'password'>(() =>
  isPasswordVisible.value ? 'text' : 'password'
)

const passwordIcon = ref<HTMLElement | null>()

const validationBar = computed(() => {
  if (!props.showValidationBar) {
    return
  }
  return node.value?.context?.value && node.value?.context?.value.length < 8
    ? ts('inputPassword.notCompliant')
    : passwordStrength(node.value?.context?.value).value
})

const barStyle = {
  red: {
    text: 'text-[#FF002E]',
    bg: 'before:bg-[#FF002E] before:w-1/3',
  },
  orange: {
    text: 'text-[#FABB05]',
    bg: 'before:bg-[#FABB05] before:w-2/3',
  },
  green: {
    text: 'text-[#38D17E]',
    bg: 'before:bg-[#38D17E] before:w-full',
  },
}

const barContext = computed(() => {
  if (!props.showValidationBar) {
    return
  }
  switch (validationBar.value) {
    case ts('inputPassword.notCompliant'):
      return {
        color: barStyle.red,
        passwordStrength: ts('inputPassword.notCompliant'),
      }
    case 'Too weak':
      return { color: barStyle.red, passwordStrength: ts('inputPassword.weak') }
    case 'Weak':
      return { color: barStyle.red, passwordStrength: ts('inputPassword.weak') }
    case 'Medium':
      return {
        color: barStyle.orange,
        passwordStrength: ts('inputPassword.medium'),
      }
    case 'Strong':
      return {
        color: barStyle.green,
        passwordStrength: ts('inputPassword.strong'),
      }
  }
})

const invalidInput = computed(() => {
  if (!props.showValidationBar) {
    return false
  }
  return (
    (passwordStrength(node.value?.context?.value).value === 'Too weak' ||
      passwordStrength(node.value?.context?.value).value === 'Weak') &&
    node.value?.parent?.context?.state.submitted
  )
})
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    :id="id"
    :type="currentInputType"
    :classes="{
      input: invalidInput ? 'text-primitives-red' : 'text-neutral-black',
      inner: 'h-xxl',
    }"
    :floating-label="true"
    :autocomplete="autocomplete ? 'current-password' : 'new-password'"
    :messages-class="'$remove:ml-2'"
  >
    <template #suffixIcon="{ state }">
      <div class="gap-x-xs h-md flex items-center">
        <button
          v-if="!state?.empty"
          ref="passwordIcon"
          type="button"
          :aria-label="
            isPasswordVisible ? $ts('hidePassword') : $ts('showPassword')
          "
          @click="togglePasswordVisibility"
        >
          <DefaultIconsHidePassword
            v-if="isPasswordVisible"
            class="h-5 w-5"
            aria-hidden="true"
          />
          <DefaultIconsShowPassword v-else class="h-5 w-5" aria-hidden="true" />
        </button>

        <div class="flex items-center">
          <button
            v-if="!state?.empty"
            type="button"
            :aria-label="$ts('accessibility.clear')"
            @click.prevent="node?.reset()"
            @mousedown.prevent
          >
            <DefaultIconsClose class="text-primitives-grey-150 h-md w-md" />
          </button>
        </div>

        <DefaultIconsWarning
          v-if="
            showValidationBar &&
            state?.rules &&
            !state.valid &&
            (state.submitted || state.blurred)
          "
          class="text-primitives-red h-5 w-5"
        />
      </div>
    </template>
    <template #help="{ value }">
      <div
        v-if="showValidationBar"
        class="gap-y-xxs mt-xxs flex w-full flex-col"
      >
        <div class="flex justify-end">
          <span
            class="text-book-8"
            :class="[
              value && value!.length! > 0
                ? barContext?.color.text!
                : 'text-transparent',
            ]"
          >
            {{ barContext?.passwordStrength }}
          </span>
        </div>
        <div
          class="relative block h-[1px] before:absolute before:bottom-0 before:left-0 before:top-0 before:z-[1]"
          :class="[
            value && value.length > 0
              ? `${barContext?.color.bg} bg-primitives-grey-100`
              : '',
          ]"
        />
      </div>
    </template>
    <template #messages="{ messages }">
      <div
        class="gap-xxs relative flex items-baseline justify-between"
        :class="{ 'mt-xs': !showValidationBar }"
        aria-live="assertive"
      >
        <ul
          v-if="Object.keys(messages).length"
          class="text-primitives-red text-light-8 ml-xs pointer-events-none"
          :class="{ 'mt-xs': showValidationBar }"
        >
          <li
            v-for="message in messages"
            :id="`${id}-${message.key}`"
            :key="message.key"
          >
            {{ message.value }}
          </li>
        </ul>
        <div
          v-if="actionText"
          class="ml-auto flex shrink-0 justify-center gap-1"
        >
          <AtomsCta
            anatomy="link"
            :button-attrs="{
              type: 'button',
            }"
            class="!text-link-8"
            :aria-haspopup="isActionTextOpeningDialog ? 'dialog' : undefined"
            @click="emit('click-action-button')"
          >
            <template #label>{{ actionText }}</template>
          </AtomsCta>
        </div>
      </div>
    </template>
  </FormKit>
</FormKitLazyProvider>

</template>

<style lang="scss" src="@design-system/assets/css/floatingLabelFormkit.scss" />
